import * as types from '../constants/actionTypes';

// import { fullTest } from '../constants/questions.js';

const initialState = {
  error: null,
  currentTestLoading: false,
  answersLoading: false,
  resultsLoading: false,
  currentTest: {
    answers: {
      answers: {},
    },
    questions: {},
    results: { positive: 0, total: 0, categories: [], score: 0, affected_categories: 0 }
  },
  tests: [],
  answers: {},
  currentSplit: '',
  testIndex: undefined,
  results: { positive: 0, total: 0, categories: [], score: 0, affected_categories: 0 },
}

const testIndex = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGOUT:
      return {
        ...state,
        answers: {},
      }
    case types.GET_TEST:
      return {
        ...state,
        currentTestLoading: true
      }
    case types.GET_TEST_SUCCESS:
      return {
        ...state,
        currentTest: action.test,
        currentTestLoading: false
      }
    case types.UPDATE_ANSWER:
      return {
        ...state,
        currentTestLoading: true
      }
    case types.UPDATE_ANSWER_SUCCESS:
      return {
        ...state,
        currentTestLoading: false,
        currentTest: { ...state.currentTest, answers: action.answers },
        currentSplit: action.answers.split,
        answers: action.answers.answers,
      }
    case types.UPDATE_ANSWER_ERROR:
      return {
        ...state,
        currentTestLoading: false,
        error: 'Probelm sending answers!'
      }
    case types.GET_ALL_TESTS_SUCCESS:
      return {
        ...state,
        tests: action.tests,
      }
    case types.GET_ANSWER_SHEET_SUCCESS:
      return {
        ...state,
        answers: action.answer_sheet.answers,
        currentSplit: action.answer_sheet.split
      }
    case types.UPDATE_ANSWER_SUCCESS:
      return {
        ...state,
        answers: action.answers.answers,
        currentSplit: action.answers.split
      }
    case types.GET_RESULTS_SUCCESS:
      return {
        ...state,
        results: action.results
      }
    case types.SET_TEST:
      return {
        ...state,
        currentTest: action.test,
      }
    case types.SET_SPLIT:
      return {
        ...state,
        currentSplit: action.split,
        currentTest: {
          ...state.currentTest,
          answers: {
            ...state.currentTest.answers,
            split: action.split
          }
        }
      }
    case types.CLEAR_SPLIT:
      return {
        ...state,
        currentSplit: '',
      }
    case types.SELECT_QUESTION:
      return {
        ...state,
        testIndex: action.id
      }
    case types.UNSELECT_QUESTION:
      return {
        ...state,
        testIndex: undefined
      }
    case types.INCREMENT_TEST_INDEX:
      const splits = state.currentTest.splits || [];
      const questions = splits[state.currentSplit] || [state.currentTest.splitQuestion]; 
      return {
        ...state,
        testIndex: Math.min(state.testIndex + 1, questions.length),
      }
    case types.DECREMENT_TEST_INDEX:
      return {
        ...state,
        testIndex: Math.max(state.testIndex - 1, 0),
      }
    case types.ADD_ANSWER:
      const answers = { ...state.answers, ...action.answer };
      const newTest = { ...state.currentTest, answers: { ...state.currentTest.answers, answers: answers } };
      return {
        ...state,
        answers: answers,
        currentTest: newTest,
      }
    case types.REMOVE_ANSWER:
      const id = `${action.questionId}`
      const { [id]: removed, ...notRemoved } = state.answers;
      return {
        ...state,
        answers: notRemoved
      }
    default:
      return state;
  }
}

export default testIndex;
