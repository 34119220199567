export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const TOGGLE_BOXED_LAYOUT = 'TOGGLE_BOXED_LAYOUT';
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSED_NAV';
export const TOGGLE_OFFCANVAS_NAV = 'TOGGLE_OFFCANVAS_NAV';
export const TOGGLE_FIXED_SIDENAV = 'TOGGLE_FIXED_SIDENAV';
export const TOGGLE_FIXED_HEADER = 'TOGGLE_FIXED_HEADER';
export const CHANGE_SIDENAV_WIDTH = 'CHANGE_SIDENAV_WIDTH';
export const TOGGLE_OFFCANVAS_MOBILE_NAV = 'TOGGLE_OFFCANVAS_MOBILE_NAV';
export const CHANGE_COLOR_OPTION = 'CHANGE_COLOR_OPTION';
export const CHANGE_THEME = 'CHANGE_THEME';

//TESTS
export const SET_TEST = 'SET_TEST';
export const SET_SPLIT = 'SET_SPLIT';
export const CLEAR_SPLIT = 'CLEAR_SPLIT';
export const SET_QUESTIONS = 'SET_QUESTIONS';
export const SELECT_QUESTION = 'SELECT_QUESTION';
export const UNSELECT_QUESTION = 'UNSELECT_QUESTION';
export const INCREMENT_TEST_INDEX = 'INCREMENT_TEST_INDEX';
export const DECREMENT_TEST_INDEX = 'DECREMENT_TEST_INDEX';

export const GET_SUBSCRIPTION = 'GET_SUBSCRIPTION';
export const GET_SUBSCRIPTION_ERROR = 'GET_SUBSCRIPTION_ERROR';
export const GET_SUBSCRIPTION_SUCCESS = 'GET_SUBSCRIPTION_SUCCESS';

export const GET_TEST = 'GET_TEST';
export const GET_TEST_ERROR = 'GET_TEST_ERROR';
export const GET_TEST_SUCCESS = 'GET_TEST_SUCCESS';

export const GET_ALL_TESTS = 'GET_ALL_TESTS';
export const GET_ALL_TESTS_ERROR = 'GET_ALL_TESTS_ERROR';
export const GET_ALL_TESTS_SUCCESS = 'GET_ALL_TESTS_SUCCESS';

export const GET_ANSWER_SHEET = 'GET_ANSWER_SHEET';
export const GET_ANSWER_SHEET_ERROR = 'GET_ANSWER_SHEET_ERROR';
export const GET_ANSWER_SHEET_SUCCESS = 'GET_ANSWER_SHEET_SUCCESS';

export const GET_RESULTS = 'GET_RESULTS';
export const GET_RESULTS_ERROR = 'GET_RESULTS_ERROR';
export const GET_RESULTS_SUCCESS = 'GET_RESULTS_SUCCESS';

export const GET_ALL_RESULTS = 'GET_ALL_RESULTS';
export const GET_ALL_RESULTS_ERROR = 'GET_ALL_RESULTS_ERROR';
export const GET_ALL_RESULTS_SUCCESS = 'GET_ALL_RESULTS_SUCCESS';


export const GET_PROFESSIONS = 'GET_PROFESSIONS';
export const GET_PROFESSIONS_SUCCESS = 'GET_PROFESSIONS_SUCCESS';
export const GET_PROFESSIONS_ERROR = 'GET_PROFESSIONS_ERROR';

export const UPDATE_ANSWER_SHEET = 'UPDATE_ANSWER_SHEET';
export const UPDATE_ANSWER_SHEET_ERROR = 'UPDATE_ANSWER_SHEET_ERROR';
export const UPDATE_ANSWER_SHEET_SUCCESS = 'UPDATE_ANSWER_SHEET_SUCCESS';

export const UPDATE_ANSWER = 'UPDATE_TEST';
export const UPDATE_ANSWER_ERROR = 'UPDATE_TEST_ERROR';
export const UPDATE_ANSWER_SUCCESS = 'UPDATE_TEST_SUCCESS';

export const ADD_ANSWER = 'ADD_ANSWER';
export const REMOVE_ANSWER = 'REMOVE_ANSWER';

//AUTH
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
