import React from 'react'
import PropTypes from 'prop-types'
// import flatten from 'flat';
import Cookies from 'js-cookie'
// import { ConnectedRouter } from 'react-router-redux'
import { Provider } from 'react-redux'
import { Route, HashRouter } from 'react-router-dom'
import ScrollToTop from '../components/ScrollToTop'
import App from './App'

import { IntlProvider, addLocaleData } from 'react-intl'
// import locale_en from 'react-intl/locale-data/en'
import locale_nl from 'react-intl/locale-data/nl'

import messages_en from 'translations/en.json'
import messages_nl from 'translations/nl.json'
// import configureStore from '../store/configureStore'
// import { verifyCredentials } from '../redux-token-auth-config'
// import { loadState } from '../localStorage'

const messages = {
  'en': messages_en,
  'nl': messages_nl,
}
// const cookie_locale = Cookies.get('language')
// const language = (cookie_locale || navigator.language).split(/[-_]/)[0];
const language = 'nl'
addLocaleData([...locale_nl])

function Root ({ store }) {

  return (
    <Provider store={store}>
      <IntlProvider locale={language} messages={messages[language]}>
        <HashRouter>
          <ScrollToTop>
            <Route path="/" component={App}/>
          </ScrollToTop>
        </HashRouter>
      </IntlProvider>
    </Provider>
  )
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
}

export default Root
