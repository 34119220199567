import * as types from '../constants/actionTypes';

const initialState = {
  loggedIn: false,
  profile: {
    id: 30,
    firstName: null,
    lastName: null,
    email: '',
    subscription: false,
    direct_debit: false,
    access_end_date: '1920-01-01T12:00:00.368Z'
  }
}

const auth = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN:
      return {
        ...state,
        loggedIn: true,
      }
    case types.LOGOUT:
      return {
        ...state,
        loggedIn: false,
      }
    case types.UPDATE_PROFILE:
      return {
        ...state,
        profile: { ...state.profile, ...action.profile }
      }
    default:
      return state;
  }
}

export default auth;
