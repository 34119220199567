import * as types from '../constants/actionTypes';

const initialState = false;

const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SUBSCRIPTION_SUCCESS:
      return action.subscription.subscription;
    default:
      return state;
  }
}

export default subscriptionReducer;
