import { createStore, compose, applyMiddleware } from 'redux'
// import reduxImmutableStateInvariant from 'redux-immutable-state-invariant'
import thunk from 'redux-thunk'
// import { createHashHistory } from 'history'
// 'routerMiddleware': the new way of storing route changes with redux middleware since rrV4.
// import { routerMiddleware } from 'react-router-redux';
// import { createMiddleware } from 'redux-beacon'
// import GoogleTagManager from '@redux-beacon/google-tag-manager'

import rootReducer from '../reducers'

// export const history = createHashHistory()

// const pageview = action => {
//   console.log('>>>pageview')
//   if (action.payload.pathname == '/thankyou' && document.referrer == '') {
//     return {}
//   } else if (action.payload.pathname == '/thankyou' && performance.navigation.type == 1) {
//     return {}
//   } else if (action.payload.pathname == '/pricing' && !action.payload.state) {
//     return {}
//   }
//   return {
//     // hitType: 'pageview',
//     // page: action.payload,
//     'event': 'Pageview',
//     'pagePath': action.payload.pathname,
//     'pageTitle': action.payload.pathname
//   }
// }

// const eventsMap = {
//   '@@router/LOCATION_CHANGE': pageview
// }

// const gtm = GoogleTagManager()
// const gtmMiddleware = createMiddleware(eventsMap, gtm)

function configureStoreProd (initialState) {
  // const reactRouterMiddleware = routerMiddleware(history);
  const middlewares = [
    // Add other middleware on this line...

    // thunk middleware can also accept an extra argument to be passed to each thunk action
    // https://github.com/gaearon/redux-thunk#injecting-a-custom-argument
    thunk,
    // reactRouterMiddleware,
    // gtmMiddleware,
  ]

  return createStore(rootReducer, initialState, compose(applyMiddleware(...middlewares)))
}

function configureStoreDev (initialState) {
  // const reactRouterMiddleware = routerMiddleware(history);
  const middlewares = [
    // Add other middleware on this line...

    // Redux middleware that spits an error on you when you try to mutate your state either inside a dispatch or between dispatches.
    // reduxImmutableStateInvariant(),

    // thunk middleware can also accept an extra argument to be passed to each thunk action
    // https://github.com/gaearon/redux-thunk#injecting-a-custom-argument
    thunk,
    // reactRouterMiddleware,
    // gtmMiddleware,
  ]

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose // add support for Redux dev tools
  return createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(...middlewares)))
}

const configureStore = process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev

export default configureStore
