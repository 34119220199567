import React from 'react';
import throttle from 'lodash/throttle';
import { render } from 'react-dom';
import configureStore from './store/configureStore';
import Root from './components/Root';
import * as serviceWorker from './serviceWorker';
import { verifyCredentials } from './redux-token-auth-config'
import { loadState, saveState } from './localStorage';

const persistedState = loadState();

const store = configureStore(persistedState);
verifyCredentials(store);
console.log(React.version)
store.subscribe(throttle(() => {
  saveState({
    tests: store.getState().tests
  });
}, 1000));

render(
    <Root store={store} />,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
