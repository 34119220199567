// redux-token-auth-config.js
import { generateAuthActions } from 'redux-token-auth'
// import { authUrl } from './constants'
const authUrl = `${process.env.REACT_APP_API_SERVER}/auth`
const config = {
  authUrl,
  userAttributes: {
    id: 'id',
    uuid: 'uuid',
    firstName: 'first_name',
    lastName: 'last_name',
    email: 'email',
    subscription: 'subscription',
    directDebit: 'direct_debit',
    subscriptionEndDate: 'access_end_date',
    lastTest: 'last_test'
  },
  userRegistrationAttributes: {
    email: 'email',
    firstName: 'first_name',
    lastName: 'last_name',
    cid: 'cid',
    userAgent: 'user_agent'
  },
  storage: {},
}

const {
  registerUser,
  signInUser,
  signOutUser,
  magicLink,
  verifyCredentials,
} = generateAuthActions(config)

export {
  registerUser,
  signInUser,
  signOutUser,
  magicLink,
  verifyCredentials,
}
