import * as types from '../constants/actionTypes';

const initialState = [];

const professionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PROFESSIONS_SUCCESS:
      return action.professions;
    default:
      return state;
  }
}

export default professionsReducer;
