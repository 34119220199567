import { combineReducers } from 'redux';
import settingsReducer from './settingsReducer';
import testReducer from './testReducer';
import authReducer from './authReducer';
import resultsReducer from './resultsReducer';
import subscriptionReducer from './subscriptionReducer';
// import { routerReducer } from 'react-router-redux';
import { reduxTokenAuthReducer } from 'redux-token-auth';
import professionsReducer from './professionsReducer'

const rootReducer = combineReducers({
  settings: settingsReducer,
  tests: testReducer,
  // routing: routerReducer,
  reduxTokenAuth: reduxTokenAuthReducer,
  auth: authReducer,
  results: resultsReducer,
  professions: professionsReducer,
  subscription: subscriptionReducer,
});

export default rootReducer;
