import React, { useEffect, useState } from 'react'
// import ReactGA from 'react-ga';
// import ReactPixel from 'react-facebook-pixel';
import Rollbar from 'rollbar';
import { Switch, Route, Redirect } from 'react-router-dom';
import loadable from 'react-loadable';
import LoadingComponent from '../components/Loading';

// 3rd
import 'styles/antd.less';
import 'styles/bootstrap/bootstrap.scss'
import GTM from 'react-tag-manager'

// custom
import "styles/layout.scss"
import "styles/theme.scss"
import "styles/ui.scss"
import "styles/vendors.scss"
import GoogleTagManager from '@redux-beacon/google-tag-manager'


let AsyncAppLayout = loadable({
  loader: () => import('components/Layout/AppLayout/'),
  loading: LoadingComponent
})
let AsyncException = loadable({
  loader: () => import('routes/exception/'),
  loading: LoadingComponent
})
let AsyncAccount = loadable({
  loader: () => import('routes/user/'),
  loading: LoadingComponent
})


function App({ match, location } ) {

  const [rollbar] = useState(new Rollbar({
          accessToken: process.env.REACT_APP_ROLLBAR_API_KEY,
          captureUncaught: true,
          captureUnhandledRejections: true,
        }))
  // constructor(props) {
  //   super(props);
  //
  //   this.state = {
  //     rollbar: new Rollbar({
  //       accessToken: process.env.REACT_APP_ROLLBAR_API_KEY,
  //       captureUncaught: true,
  //       captureUnhandledRejections: true,
  //     })
  //   };
  //
  //   // this.props.history.listen( (location, action) => {
  //     // window.gtag('config', process.env.REACT_APP_GA_CLIENT_ID, {'page_path': location.pathname });
  //     // ReactGA.initialize(process.env.REACT_APP_GA_CLIENT_ID);
  //     // const options = { autoConfig: true }
  //
  //     // ReactPixel.init(process.env.REACT_APP_FB_PIXEL_ID, {}, options);
  //     // ReactPixel.pageView();
  //     // window.dataLayer = window.dataLayer || [];
  //     // window.dataLayer.push({
  //     //  'event': 'Pageview',
  //     //  'pagePath': location.pathname,
  //     //  'pageTitle': location.pathname
  //     // });
  //   // })
  // }


  useEffect(() => {
    const { pathname } = location
    const gtm = GoogleTagManager()
    const payload = { 'event': 'Pageview', 'pagePath': pathname, 'pageTitle': pathname }
    console.debug('GoogleTagManager', payload )
    gtm([payload])
  }, [location])

    const isRoot = location.pathname === '/';
    if (isRoot) {
      return ( <Redirect to={'/tests/autism'}/> );
    }

    return (
      <div id="app">
      <Switch>
        <Route path={`${match.url}exception`} component={AsyncException} />
        <Route path={`${match.url}user`} component={AsyncAccount} />
        <Route path={`${match.url}`}  component={AsyncAppLayout} />
      </Switch>
      </div>
    );
}

export default App;
