import * as types from '../constants/actionTypes';

const initialState = [];

const resultsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALL_RESULTS_SUCCESS:
      return action.results;
    default:
      return state;
  }
}

export default resultsReducer;
